
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            List of wallet report
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <Dropdown
                v-model="filters.duration"
                :options="duration"
                @change="topFilter($event.value, 'duration')"
                placeholder="Select a type"
                class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <span :class="'customer-badge status-' + slotProps.option">{{
                    slotProps.option
                  }}</span>
                </template>
              </Dropdown>
              <!-- <InputText v-model="filters.global"  @keydown.enter="onFilter($event)" placeholder="Global Search" /> -->
            </span>
          </div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
        <Column
          header="Name"
          :sortable="true"
          sortField="name"
          filterField="name"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Name</span>

            {{ slotProps.data.user.name }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.name"
              @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
        </Column>

        <Column
          header="Email"
          :sortable="true"
          sortField="email"
          filterField="email"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Email</span>
             <span
              class="text-truncate d-inline-block"
              style="max-width: 100px"
              :title="slotProps.data.user.email"
              >{{ slotProps.data.user.email }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.email"
              @keydown.enter="topFilter(filters.email, 'email')"
              class="p-column-filter"
              placeholder="Search by Email"
            />
          </template>
        </Column>

        <Column
          header="Currency"
          :sortable="true"
          sortField="currency"
          filterField="currency"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Currency</span>

            {{ slotProps.data.currency }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.currency"
              @keydown.enter="topFilter(filters.currency, 'currency')"
              class="p-column-filter"
              placeholder="Search by Currency"
            />
          </template>
        </Column>

        <Column field="type" header="Type" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Type</span>
            {{ slotProps.data.type }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters.type"
              @change="topFilter($event.value, 'type')"
              :options="types"
              placeholder="Select a type"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
            <!-- <InputText type="text" v-model="filters.type"  @keydown.enter="onFilter($event)" class="p-column-filter" placeholder="Search by type"/> -->
          </template>
        </Column>

        <Column
          header="Transaction Hash"
          :sortable="true"
          sortField="transactionHash"
          filterField="transactionHash"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Transaction Hash</span>

            <span
              class="text-truncate d-inline-block"
              style="max-width: 100px"
              :title="
                slotProps.data.transfer_detail == null
                  ? 'N/A'
                  : slotProps.data.transfer_detail.transactionHash
              "
            >
              {{
                slotProps.data.transfer_detail == null
                  ? "N/A"
                  : slotProps.data.transfer_detail.transactionHash
              }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.transactionHash"
              @keydown.enter="
                topFilter(filters.transactionHash, 'transactionHash')
              "
              class="p-column-filter"
              placeholder="Search by transactionHash"
            />
          </template>
        </Column>
        <Column
          header="Amount"
          :sortable="true"
          sortField="amount"
          filterField="amount"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Amount</span>

            {{ slotProps.data.amount }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.amount"
              @keydown.enter="topFilter(filters.amount, 'amount')"
              class="p-column-filter"
              placeholder="Search by amount"
            />
          </template>
        </Column>
        <Column
          header="Date"
          :sortable="true"
          sortField="created_at"
          filterField="created_at"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            <span
              :class="'customer-badge status-' + slotProps.data.created_at"
              >{{
                new Date(slotProps.data.created_at).toLocaleDateString("en-US")
              }}</span
            >
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              :showButtonBar="true"
              dateFormat="yy-mm-dd"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Transaction Date"
            />
          </template>
        </Column>

        <Column
          field="status"
          header="Status"
          :sortable="true"
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            <span :class="'customer-badge status-' + slotProps.data.status">{{
              slotProps.data.status
            }}</span>
          </template>
          <template #filter>
            <!-- <InputText type="text" v-model="filters.status" class="p-column-filter"  @keydown.enter="onFilter($event)" placeholder="Search by status"/> -->
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'status')"
              :options="statuses"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown> </template
          >>
        </Column>
      </DataTable>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";

import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";
// import MultiSelect from 'primevue/multiselect';
import Dropdown from "primevue/dropdown";

import Calendar from "primevue/calendar";
// import ProgressBar from 'primevue/progressbar';

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Dropdown,
    Calendar,
    Column,
    InputText,
  },
  data() {
    return {
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: " Wallet Report",
      items: [
        {
          text: "Wallet",
          href: "#",
        },
        {
          text: "Report",
          active: true,
        },
      ],
      filters: {
        duration: null,
        name: null,
        email: null,
        currency: null,
        transactionHash: null,
        type: null,
        amount: null,
        date: null,
        status: null,
      },
      filter: "",
      customers: [],
      selectedCustomers: null,
      loading: true,
      statuses: ["completed", "pending"],
      types: ["withdraw"],
      duration: ["day", "week", "month"],
    };
  },
  created() {
    // this.customerService = new CustomerService();
  },
  mounted() {
    this.wallet();
    // this.customerService.getCustomersLarge().then(data => {
    //     this.customers = data;
    // this.loading = false;
    // });
  },
  methods: {
    async wallet() {
      await ApiClass.getRequest(
        "admin/report/wallet?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;

          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
          // console.log(this.customers);
        }
      });
    },
    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }

      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }

      this.wallet();
      // this.onLazyEvent();
    },
    onSort(event) {
      this.loading = !this.loading;
      var sortby = event.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + event.sortField;
      this.wallet();
    },
    onFilter() {
      //?name=manjit
      // &type=withdraw
      // &status=completed
      // &amount=102
      // &duration=custom&from=2021-10-25&to=2021-10-26
      // var query;
      // this.filters.forEach(element => {
      //     console.log(element);
      // });
      //   console.log(this.filters);
    },

    topFilter(columnVal, columnName) {
      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.wallet();
    },

    /* topFilter(e,query){
            console.log('looooo', this.filters);
            if(query == 'date'){
                var up_date=e.toLocaleDateString("en-US", { year: 'numeric' }) 
            + "-"+ e.toLocaleDateString("en-US", { month: 'numeric' })
            + "-" + e.toLocaleDateString("en-US", { day: 'numeric' }) ;
            e = up_date;
            console.log(e);
            }



            this.filter = x;
            this.wallet();



             /*   this.loading = !this.loading;
                 var val = e == null ?'' : e;
                //  var val = e.value == null ?'' : e.value;
                 if(this.filter.search(query) != -1){
                     
                     var search = this.filter.substring(1);
                    let x = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
                     delete x[query] ;
                    var queryString = Object.keys(x).map(key => key + '=' + x[key]).join('&'); 
                         this.filter = "&"+queryString
                         this.wallet();  
                 }
                 else{
                      var x =this.filter+"&" + query + "="+ val;
                // console.log('xis ',x);
                this.filter = x
                this.wallet();

                 } 
  
           
        } */
  },
};
</script>
  <style lang="scss">
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-calendar .p-datepicker {
  width: 350px;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>